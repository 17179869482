import React from 'react'
import Layout from '@/components/layout'
import Seo from '@/components/seo'
import Faq from '@/components/faq'
import ScrollToTopButton from '@/components/scrollToTopButton'
import Contact from '@/components/contact'
import { graphql, Link } from 'gatsby'
import PageTitle from '@/components/pageTitle'
import ArticleList from '@/components/articleList'
import Breadcrumb from '@/components/breadcrumb'
import ArticleListWidget from '@/components/articleListWidget'
import PropTypes from 'prop-types'
import '../styles/category.scss'

const Category = ({ data, pageContext }) => {
  const { name } = pageContext
  const category = data.category
  const subCategories = data.subCategories.nodes
  const articles =
    data.articles.nodes.length > 0
      ? data.articles.nodes
      : data.subArticles.nodes
  const contents = (() => {
    if (subCategories.length > 0) {
      return subCategories.map((subCategory) => {
        const items = articles.filter(
          (article) => article.frontmatter.sub_category === subCategory.name
        )
        if (items.length === 0) return

        return (
          <div key={subCategory.id}>
            <h2 className="category_container_ttl">
              <Link to={`/categories/${subCategory.name}/`}>
                {subCategory.name}
              </Link>
            </h2>
            <ArticleList
              articles={items}
              className="category_container"
              category={subCategory.name}
            />
          </div>
        )
      })
    }
    return (
      <ArticleList
        articles={articles}
        className="category_container"
        category={name}
      />
    )
  })()

  const breadcrumb = [{ title: name }]
  if (category?.category) {
    breadcrumb.unshift({
      title: category.name,
      path: `/categories/${category.name}/`,
    })
  }
  return (
    <Layout search={true}>
      <Seo title={name} />
      <Breadcrumb items={breadcrumb} />
      <PageTitle title={name} />
      <div className="wraper main_contents">
        <main className="category_container">
          <section>{contents}</section>
        </main>
        <aside className="category">
          {name !== 'ユーザー向け機能説明' ? (
            <ArticleListWidget
              className="bl_guideUser"
              category="ユーザー向け機能説明"
              title="ユーザー向け機能説明"
            />
          ) : (
            ''
          )}

          {name !== '管理者向け機能説明' ? (
            <ArticleListWidget
              className="bl_guideAdmin"
              category="管理者向け機能説明"
              title="管理者向け機能説明"
            />
          ) : (
            ''
          )}
        </aside>
      </div>
      <Faq />
      <Contact />
      <ScrollToTopButton />
    </Layout>
  )
}

Category.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
  }),
}

export default Category

export const query = graphql`
  query ($name: String!, $category: String) {
    category: categoriesJson(name: { eq: $category }) {
      name
      category
      id
    }
    subCategories: allCategoriesJson(filter: { category: { eq: $name } }) {
      nodes {
        name
        category
        id
      }
    }
    articles: allMdx(
      filter: { frontmatter: { category: { eq: $name } } }
      sort: { order: ASC, fields: fields___order }
    ) {
      nodes {
        id
        frontmatter {
          title
          sub_category
          category
        }
        fields {
          slug
        }
      }
    }
    subArticles: allMdx(
      filter: {
        frontmatter: {
          category: { eq: $category }
          sub_category: { eq: $name }
        }
      }
      sort: { order: ASC, fields: fields___order }
    ) {
      nodes {
        id
        frontmatter {
          title
          sub_category
          category
        }
        fields {
          slug
        }
      }
    }
  }
`
